import store from "@/store";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: HomeView,
    beforeEnter: (to, from, next) => {
      store.dispatch("initChangeRoute", to.path);
      next();
    },
  },
  {
    path: "/about",
    name: "about",
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    beforeEnter: (to, from, next) => {
      store.dispatch("initChangeRoute", to.path);
      next();
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      store.dispatch("initChangeRoute", to.path);
      next();
    },
  },
  {
    path: "/create-customer",
    name: "create-customer",
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      updateCustomer: String(route.query.booleanParam).toLowerCase() === "true",
    }),
    component: () => import("@/views/AdministrateCustomers.vue"),
    beforeEnter: (to, from, next) => {
      store.dispatch("initChangeRoute", to.path);
      next();
    },
  },
  {
    path: "/edit-customer/:id/edit",
    name: "edit-customer",
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      updateCustomer: String(route.query.booleanParam).toLowerCase() === "true",
    }),
    component: () => import("@/views/AdministrateCustomers.vue"),
    beforeEnter: (to, from, next) => {
      store.dispatch("initChangeRoute", to.path);
      next();
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const jwtToken = localStorage.getItem("jwtToken");
    if (!jwtToken) {
      next({ name: "login" });
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
